import React, { FC } from "react";
import { MainLayout } from "@/layouts/MainLayout";
import Helmet from "react-helmet";
import { SEO } from "@/components/basic/SEO";
import { graphql, PageProps } from "gatsby";
import { Link } from "@/components/basic/Link";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";

const Privacy: FC<PageProps<GatsbyTypes.Query>> = ({ data }) => (
  <MainLayout>
    <Helmet>
      <body className="ltr privacy" id="intro" />
    </Helmet>
    <SEO
      title="Data Protection Declaration"
      description="ANIXE is a leading travel technology provider specialized
      in third-party hotel supplier aggregation and distribution, accelerated
      search and response, offline rate and availability exports in various formats,
      hotel and room type automated mapping and high data load and traffic management."
    />
    <section className="hero-section hero-section-privacy color-bg-light">
      <div className="l-wrapper clearfix">
        <div className="l-8-cols-tablet">
          <h1 className="color-primary">Data Protection Declaration</h1>
        </div>
      </div>
    </section>
    <div className="l-wrapper color-bg-negative clearfix text-doc">
      <div className="l-8-cols-desktop l-with-dbl-vertical-gutters-mobile l-island clearfix">
        {documentToReactComponents(
          data.contentfulPrivacyPolicyPage.privacyPolicyContent.json
        )}
      </div>
    </div>
    <SiteFooterWrapper>
      {/*<Link*/}
      {/*  classname="button button-quaternary-negative-dark button-estimate-project"*/}
      {/*  url="/estimate-project"*/}
      {/*  text="estimate project"*/}
      {/*/>*/}
      <Link
        classname="button button-primary button-request-demo"
        url="/demo-form"
        text="request a demo"
      />
    </SiteFooterWrapper>
  </MainLayout>
);
export default Privacy;
export const query = graphql`
  {
    contentfulPrivacyPolicyPage {
      privacyPolicyContent {
        json
      }
      title
    }
  }
`;
